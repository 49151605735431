function splitBox(w, h, x, y, vOrH, s, reverse=0) {
  var boxes={box1:{}, box2:{}};
  if (!reverse) {
    if (vOrH==1) { 
      boxes.box1.W=w*s;
      boxes.box1.H=h;
      boxes.box1.X=x;
      boxes.box1.Y=y;
      boxes.box2.W=w*(1-s);
      boxes.box2.H=h;
      boxes.box2.X=x+w*s;
      boxes.box2.Y=y;
    } else {
      boxes.box1.W=w;
      boxes.box1.H=h*s;
      boxes.box1.X=x;
      boxes.box1.Y=y;
      boxes.box2.W=w;
      boxes.box2.H=h*(1-s);
      boxes.box2.X=x;
      boxes.box2.Y=y+h*s;
    }
  } else {
    if (vOrH==1) { 
      boxes.box2.W=w*s;
      boxes.box2.H=h;
      boxes.box2.X=x;
      boxes.box2.Y=y;
      boxes.box1.W=w*(1-s);
      boxes.box1.H=h;
      boxes.box1.X=x+w*s;
      boxes.box1.Y=y;
    } else {
      boxes.box2.W=w;
      boxes.box2.H=h*s;
      boxes.box2.X=x;
      boxes.box2.Y=y;
      boxes.box1.W=w;
      boxes.box1.H=h*(1-s);
      boxes.box1.X=x;
      boxes.box1.Y=y+h*s;
    }



  }
  return boxes;
}


function combineArrays(arr1, arr2) {
  let result = [];

  for (let i = 0; i < arr1.length; i++) {
    for (let j = 0; j < arr2.length; j++) {
      result.push([arr1[i], arr2[j]]);
    }
  }

  return result;
}

function getMinMaxLen(text) {

  var words=text.split(' ');
  var index=0;
  var maxLen=0;
  var minLen=100;
  for (let i = 0; i < words.length; i++) {
    if (words[i].length>maxLen) {maxLen=words[i].length;index=i;}
    if (words[i].length<minLen) {minLen=words[i].length;}

  }


  return [minLen, maxLen];

}

function getNumAuthors(au) {


au=(au.split( /,/ ).reduce( (a,b, i) => i %2 == 0 ? a + ";" + b : a + "," + b ) );
var authors=au.split(';');
const numAuthors=authors.length;
var authors2=[];
authors.forEach(au => {
  var temp=au.split(',');

  var auTemp='';
  if (temp[1]) {auTemp=temp[1].trim()+' '+temp[0].trim()} else {auTemp=temp[0].trim()}
  authors2.push(auTemp.trim());

});
au=authors2.join(', ');
return [au, numAuthors];

}

export default function genDesign(title,canvasWidth, canvasHeight,orientation,numElements, cluster) {
  var design={};
  const textCases=['upper'];//['proper','proper','upper'];//['upper', 'proper','lower'];//,'proper'];//['upper','proper'];//lower

  const textAlignments=['left', 'center'];
  const textDirection=['up'];//['up','down','middle'];

  const fontCombos=[
    {id:"CormonrantGaramond", tiFont: "Cormorant Garamond", stiFont:"Cormorant Garamond", auFont:"Cormorant Garamond"},
    {id:"MontessaratFiraCode", tiFont: "Montserrat", stiFont:"Fira Code", auFont:"Fira Code"},
     {id:"OswaldQuattrocento", tiFont: "Oswald", stiFont:"Quattrocento", auFont:"Quattrocento"},
     {id:"cyborg", tiFont: "Roboto", stiFont:"Roboto", auFont:"Roboto"},
     {id: "LoraRoboto", tiFont: "Lora", stiFont:"Roboto", auFont:"Roboto"},
     {id: "PlayfairDisplaySourceSansPro", tiFont: "Playfair Display", stiFont:"Source Sans Pro", auFont:"Source Sans Pro"},
     {id: "Neucha", tiFont: "Neucha", stiFont:"Neucha", auFont:"Neucha"},
     {id: "News", tiFont: "News Cycle", stiFont:"News", auFont:"News"},
     {id: "lux", tiFont: "Nunito Sans", stiFont:"Nunito Sans", auFont:"Nunito Sans"},
     {id: "sketch", tiFont: "'Cabin Sketch', cursive", stiFont:"Neucha", auFont:"Neucha"},
     {id: "expletus", tiFont: "Expletus Sans", stiFont:"Hind", auFont:"Hind"},
      {id: "Bubblegum", tiFont: "Bubblegum Sans", stiFont:"Open Sans", auFont:"Open Sans"},
      {id: "Mulish", tiFont: "Mulish", stiFont:"Space Mono", auFont:"Space Mono"},
      
   ];

  const fontEffects=["regular"];//["regular", "regular", "regular","bold","italic"];//,"regular","regular", "bold","bold", "italic"];

  design.canvasWidth=canvasWidth;
  design.canvasHeight=canvasHeight;
  
  design.ti=title.title.trim();
  design.sti=title.subtitle.trim();
  design.au=title.au.trim();




var boxLayout={ti:{},sti:{},au:{}};

const layouts=[



  {id:"0", areas: [
  //  {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.9, H: 0.95, tiA: 0.15, stiA: 0.06, auA: 0.04, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.1, auPadding: 0, maxTiNumLines: 2},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
  /*  {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.99, tiA: 0.1, stiA: 0.05, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
  */],
  },
  
  {id:"1", areas: [
    {tiCase: 'upper', stiCase: 'proper', auCase: 'proper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.9, H: 0.95, tiA: 0.15, stiA: 0.05, auA: 0.04, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.1, auPadding: 0, maxTiNumLines: 2},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.99, tiA: 0.15, stiA: 0.05, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 

    ],
  },
  {id:"2", areas: [
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.9, H: 0.95, tiA: 0.08, stiA: 0.08, auA: 0.04, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.1, auPadding: 0, maxTiNumLines: 2},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.99, tiA: 0.08, stiA: 0.05, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
  ],
  },
  {id:"3", areas: [
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.9, H: 0.95, tiA: 0.07, stiA: 0.05, auA: 0.04, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.01, auPadding: 0, maxTiNumLines: 1},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.99, tiA: 0.05, stiA: 0.03, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.01, auPadding: 0.01, maxTiNumLines: 1}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
  ],
  },
  {id:"4",areas: [
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.92, H: 0.95, tiA: 0.08, stiA: 0.06, auA: 0.04, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0.05, auPadding: 0, maxTiNumLines: 1},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.95, tiA: 0.08, stiA: 0.05, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 

    /*   {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.9, H: 0.95, tiA: 0.08, stiA: 0.05, auA: 0.05, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0, auPadding: 0},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.95, tiA: 0.08, stiA: 0.05, auA: 0.04, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0, auPadding: 0},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.7, tiA: 0.08, stiA: 0.05, auA: 0.04, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0, auPadding: 0},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.4, tiA: 0.50, stiA: 0.3, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0, auPadding: 0},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.3, H: 0.99, tiA: 0.18, stiA: 0.1, auA: 0.05, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0, auPadding: 0}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.25, H: 0.5, tiA: 0.5, stiA: 0.2, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0, auPadding: 0}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.5, H: 0.5, tiA: 0.5, stiA: 0.2, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0, auPadding: 0}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.5, H: 0.9, tiA: 0.2, stiA: 0.05, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0, auPadding: 0}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.9, H: 0.9, tiA: 0.2, stiA: 0.08, auA: 0.05, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0, auPadding: 0}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.99, tiA: 0.15, stiA: 0.05, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0, auPadding: 0}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.5, H: 0.9, tiA: 0.2, stiA: 0.05, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0, auPadding: 0}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up',W: 0.9, H: 0.9, tiA: 0.2, stiA: 0.08, auA: 0.05, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0, auPadding: 0}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.99, tiA: 0.15, stiA: 0.05, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0, auPadding: 0}, 
   */ ],
  },
  {id:"5", areas: [
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.9, H: 0.95, tiA: 0.08, stiA: 0.05, auA: 0.04, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.01, auPadding: 0, maxTiNumLines: 1},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.95, tiA: 0.05, stiA: 0.04, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
  ],
  },
  {id:"6", areas: [
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.9, H: 0.95, tiA: 0.1, stiA: 0.04, auA: 0.04, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.01, auPadding: 0, maxTiNumLines: 2},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.01, auPadding: 0.01, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
  ],
   },
   {id:"7", areas: [
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.92, H: 0.95, tiA: 0.1, stiA: 0.06, auA: 0.04, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0.05, auPadding: 0, maxTiNumLines: 1},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.95, tiA: 0.05, stiA: 0.05, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
  ],
   },
   {id:"8", areas: [
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.92, H: 0.95, tiA: 0.08, stiA: 0.06, auA: 0.04, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0.05, auPadding: 0, maxTiNumLines: 2},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.99, tiA: 0.08, stiA: 0.05, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
  ],
   },
   {id:"9", areas: [
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.92, H: 0.95, tiA: 0.12, stiA: 0.04, auA: 0.04, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0, maxTiNumLines: 2},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.95, tiA: 0.1, stiA: 0.04, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
  ],
   },
   {id:"10", areas: [
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.92, H: 0.95, tiA: 0.1, stiA: 0.06, auA: 0.04, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.05, auPadding: 0, maxTiNumLines: 1},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.95, tiA: 0.07, stiA: 0.04, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
  ],
   },
   {id:"11", areas: [
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.92, H: 0.95, tiA: 0.1, stiA: 0.06, auA: 0.04, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0.03, auPadding: 0, maxTiNumLines: 1},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.95, tiA: 0.1, stiA: 0.05, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
  ],
   },
   {id:"12", areas: [
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.92, H: 0.95, tiA: 0.1, stiA: 0.06, auA: 0.04, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0.05, auPadding: 0, maxTiNumLines: 1},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.99, tiA: 0.1, stiA: 0.05, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
  ],
   },
   {id:"13", areas: [
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.92, H: 0.95, tiA: 0.1, stiA: 0.06, auA: 0.04, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0.05, auPadding: 0, maxTiNumLines: 1},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.95, tiA: 0.09, stiA: 0.05, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
  ],
   },
   {id:"14", areas: [
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.92, H: 0.95, tiA: 0.15, stiA: 0.06, auA: 0.04, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0.05, auPadding: 0, maxTiNumLines: 1},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.95, tiA: 0.1, stiA: 0.05, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
  ],
   },
   {id:"15", areas: [
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.92, H: 0.95, tiA: 0.15, stiA: 0.06, auA: 0.04, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0.05, auPadding: 0, maxTiNumLines: 2},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.95, tiA: 0.1, stiA: 0.05, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
  ],
   },    
   {id:"16", areas: [
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.92, H: 0.95, tiA: 0.1, stiA: 0.06, auA: 0.04, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0.05, auPadding: 0, maxTiNumLines: 1},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.95, tiA: 0.08, stiA: 0.05, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
  ],
   },          
   {id:"17", areas: [
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.92, H: 0.95, tiA: 0.1, stiA: 0.06, auA: 0.04, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0.05, auPadding: 0, maxTiNumLines: 1},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.99, tiA: 0.1, stiA: 0.05, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
  ],
   },                     
   {id:"18", areas: [
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.92, H: 0.95, tiA: 0.08, stiA: 0.04, auA: 0.04, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0.05, auPadding: 0, maxTiNumLines: 1},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.99, tiA: 0.08, stiA: 0.04, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
  ],
   }, 
   {id:"19", areas: [
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.92, H: 0.95, tiA: 0.12, stiA: 0.06, auA: 0.04, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0, stiPadding: 0.05, auPadding: 0, maxTiNumLines: 2},
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.85, H: 0.9, tiA: 0.15, stiA: 0.08, auA: 0.03, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.05, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'middle', auD:'up', W: 0.4, H: 0.95, tiA: 0.80, stiA: 0.1, auA: 0.1, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.01, stiPadding: 0.05, auPadding: 0.05, maxTiNumLines: 3},     
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7, H: 0.95, tiA: 0.12, stiA: 0.05, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 2}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.3, stiA: 0.06, auA: 0.03, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 3}, 
    {tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'up', stiD:'down', auD:'up', W: 0.8, H: 0.95, tiA: 0.07, stiA: 0.03, auA: 0.3, tiFontEffect: 'regular', stiFontEffect: 'regular', auFontEffect: 'regular', tiPadding: 0.03, stiPadding: 0.03, auPadding: 0.03, maxTiNumLines: 1}, 
  ],
   }, 
];

const layout=layouts.find(item => item.id == cluster);//layouts[19];//Math.floor(Math.random()*layouts.length)];
//console.log(layout);
var area=layout.areas[Math.floor(Math.random()*layout.areas.length)];


design.tiAlign=area.align;
design.tiDirection=area.tiD;

design.stiAlign=area.align;
design.stiDirection=area.stiD;

design.auAlign=area.align;
design.auDirection=area.auD;

design.tiCase=area.tiCase;
design.stiCase=area.stiCase;
design.auCase=area.auCase;




design.hPaddingStart=0;
design.hPaddingEnd=0;
design.hPaddingTop=0;
design.hPaddingBottom=0;

/*

design.tiPaddingStart=0.01;
if (layout.tiPaddingEnd) { design.tiPaddingEnd=layout.tiPaddingEnd; } else {design.tiPaddingEnd=0;}
design.tiPaddingTop=0.01;
design.tiPaddingBottom=0.01;

if (layout.stiPaddingStart) { design.stiPaddingStart=layout.stiPaddingStart; } else {design.stiPaddingStart=0.01;}
design.stiPaddingEnd=0.01;
design.stiPaddingTop=0.01;
design.stiPaddingBottom=0.01;

design.auPaddingStart=0;
design.auPaddingEnd=0;
design.auPaddingTop=0;
design.auPaddingBottom=0;

*/

boxLayout.id=layout.id;



//var wHs=combineArrays(widths, heights);
var tiA=area.H*area.W*area.tiA;
var stiA=area.H*area.W*area.stiA;
var auA=area.H*area.W*area.auA;



var H=area.H;//selectedWH.H;
if (area.align=='center') {
  var Y=(1-H)/2;  
} else {
  var Y=(1-H)/2;
}

var W=area.W;//selectedWH.W;
if (area.align=='center') {
  var X=(1-W)/2;
} else  {
  var X=(1-W);
} 
var splitRatio=tiA/(W*H);//splitRatios[Math.floor(Math.random()*splitRatios.length)];
var boxes1=splitBox(W, H , X, Y, Math.random()*1 > 0 ? 0 : 1, splitRatio,layout.reverse);

if (layout.type!='tiAlone') {
  var splitRatio2=stiA/(boxes1.box2.W*boxes1.box2.H);//splitRatios2[Math.floor(Math.random()*splitRatios2.length)];
  var boxes2=splitBox(boxes1.box2.W,boxes1.box2.H, boxes1.box2.X, boxes1.box2.Y, Math.random()*1 > 0 ? 0 : 1, splitRatio2,layout.reverse);

  var splitRatio3=1-(auA/(boxes2.box2.W*boxes2.box2.H));//splitRatios3[Math.floor(Math.random()*splitRatios3.length)];
  var boxes3=splitBox(boxes2.box2.W,boxes2.box2.H, boxes2.box2.X, boxes2.box2.Y, Math.random()*1 > 0 ? 0 : 1, splitRatio3,layout.reverse);

  boxLayout.ti=boxes1.box1;//boxes[layout.tiBox];//boxes1.box1;
  boxLayout.sti=boxes2.box1;//boxes[layout.stiBox];//boxes3.box1;
  boxLayout.au=boxes3.box2;//boxes[layout.auBox];//boxes4.box2;

} else {
  var splitRatio2=1-((stiA+auA)/(boxes1.box2.W*boxes1.box2.H));//splitRatios2[Math.floor(Math.random()*splitRatios2.length)];
  var boxes2=splitBox(boxes1.box2.W,boxes1.box2.H, boxes1.box2.X, boxes1.box2.Y, Math.random()*1 > 0 ? 0 : 1, splitRatio2,layout.reverse);

  var splitRatio3=(stiA/(boxes2.box2.W*boxes2.box2.H));//splitRatios3[Math.floor(Math.random()*splitRatios3.length)];
  var boxes3=splitBox(boxes2.box2.W,boxes2.box2.H, boxes2.box2.X, boxes2.box2.Y, Math.random()*1 > 0 ? 0 : 1, splitRatio3,layout.reverse);

  boxLayout.ti=boxes1.box1;//boxes[layout.tiBox];//boxes1.box1;
  boxLayout.sti=boxes3.box1;//boxes[layout.stiBox];//boxes3.box1;
  boxLayout.au=boxes3.box2;//boxes[layout.auBox];//boxes4.box2;


}

//var splitRatio=splitRatios[Math.floor(Math.random()*splitRatios.length)];
//var boxes4=splitBox(boxes3.box2.W,boxes3.box2.H, boxes3.box2.X, boxes3.box2.Y, Math.random()*1 > 0 ? 0 : 1, splitRatio,layout.reverse);
//var boxes5=splitBox(boxes4.box2.W,boxes4.box2.H, boxes4.box2.X, boxes4.box2.Y, Math.random()*1 > 0 ? 0 : 1, splitRatio);




design.maxTiNumLines=area.maxTiNumLines;

 const fontCombo=fontCombos[Math.floor(Math.random()*fontCombos.length)];
 design.tiFont=fontCombo.tiFont;
 design.stiFont=fontCombo.stiFont;
 design.suFont=fontCombo.auFont;

  //design.tiAlign=boxLayout.ti.align;//textAlignments[Math.floor(Math.random()*textAlignments.length)];
  //design.stiAlign=boxLayout.sti.align;
  //design.auAlign='right';//boxLayout.au.align;
  design.tiFontEffect=area.tiFontEffect;//'bold';//'regular';//'bold';//fontEffects[Math.floor(Math.random()*fontEffects.length)];


  //design.tiFontSize=tiFontSizes[Math.floor(Math.random()*tiFontSizes.length)]
  design.stiFontEffect=area.stiFontEffect;//'regular';//fontEffects[Math.floor(Math.random()*fontEffects.length)];
  //design.stiFontSize=stiFontSizes[Math.floor(Math.random()*stiFontSizes.length)]
  design.auFontEffect=area.auFontEffect;//fontEffects[Math.floor(Math.random()*fontEffects.length)];
  //design.auFontSize=auFontSizes[Math.floor(Math.random()*auFontSizes.length)]

  design.boxLayoutId=W+H+tiA+stiA+auA+layout.id;//boxLayout.id;
  //design.p=boxLayout.p;

 


  design.tiX=Math.floor(boxLayout.ti.X*design.canvasWidth);//xs[Math.floor(Math.random()*3)]*design.canvasWidth;//Math.floor(boxLayout.ti.X*design.canvasWidth);
  //const tiYIndex=Math.floor(Math.random()*ys.length);
  design.tiY=Math.floor(boxLayout.ti.Y*design.canvasHeight);//ys[tiYIndex]*design.canvasHeight;//Math.floor(boxLayout.ti.Y*design.canvasHeight);
  //design.tiDirection=boxLayout.ti.direction;//'middle';//boxLayout.ti.direction;
  design.tiWidth=Math.floor(boxLayout.ti.W*design.canvasWidth);//design.canvasWidth-design.tiX;//Math.floor(boxLayout.ti.W*design.canvasWidth);
  design.tiHeight=Math.floor(boxLayout.ti.H*design.canvasHeight);//design.canvasHeight/4;//Math.floor(boxLayout.ti.H*design.canvasHeight);
  design.tiX+=Math.floor(area.tiPadding*design.tiWidth);//design.p/2*design.tiWidth;
  design.tiY+=Math.floor(area.tiPadding*design.tiHeight);//design.p/2*design.tiHeight;
  design.tiWidth-=Math.floor((area.tiPadding+area.tiPadding)*design.tiWidth);//design.p*design.tiWidth;
  design.tiHeight-=Math.floor((area.tiPadding+area.tiPadding)*design.tiHeight);//design.p*design.tiHeight;



  design.stiX=Math.floor(boxLayout.sti.X*design.canvasWidth);//design.tiX;//xs[Math.floor(Math.random()*3)]*design.canvasWidth;//Math.floor(boxLayout.sti.X*design.canvasWidth);
  //ys.splice(tiYIndex,1);
  //const stiYIndex=Math.floor(Math.random()*ys.length);
  design.stiY=Math.floor(boxLayout.sti.Y*design.canvasHeight);//ys[stiYIndex]*design.canvasHeight;//Math.floor(boxLayout.sti.Y*design.canvasHeight);
  //design.stiDirection=boxLayout.sti.direction;//'middle';//boxLayout.sti.direction;
  design.stiWidth=Math.floor(boxLayout.sti.W*design.canvasWidth);//design.canvasWidth-design.stiX;//Math.floor(boxLayout.sti.W*design.canvasWidth);
  design.stiHeight=Math.floor(boxLayout.sti.H*design.canvasHeight);//design.canvasHeight/4;//Math.floor(boxLayout.sti.H*design.canvasHeight);
  design.stiX+=Math.floor(area.stiPadding*design.stiWidth);//design.p/2*design.stiWidth;
  design.stiY+=Math.floor(area.stiPadding*design.stiHeight);//design.p/2*design.stiHeight;
  design.stiWidth-=Math.floor((area.stiPadding+area.stiPadding)*design.stiWidth);//design.p*design.stiWidth;
  design.stiHeight-=Math.floor((area.stiPadding+area.stiPadding)*design.stiHeight);//design.p*design.stiHeight;

  //ys.splice(stiYIndex,1);

  design.auX=Math.floor(boxLayout.au.X*design.canvasWidth);//design.tiX;//xs[Math.floor(Math.random()*3)]*design.canvasWidth;//Math.floor(boxLayout.au.X*design.canvasWidth);
  design.auY=Math.floor(boxLayout.au.Y*design.canvasHeight);//ys[Math.floor(Math.random()*ys.length)]*design.canvasHeight;//Math.floor(boxLayout.au.Y*design.canvasHeight);
  //design.auDirection='up';//boxLayout.au.direction;//'middle';//boxLayout.au.direction;
  design.auWidth=Math.floor(boxLayout.au.W*design.canvasWidth);//design.canvasWidth-design.auX;//Math.floor(boxLayout.au.W*design.canvasWidth);
  design.auHeight=Math.floor(boxLayout.au.H*design.canvasHeight);//design.canvasHeight/4;//Math.floor(boxLayout.au.H*design.canvasHeight);
  design.auX+=Math.floor(area.auPadding*design.auWidth);//design.p/2*design.stiWidth;
  design.auY+=Math.floor(area.auPadding*design.auHeight);//design.p/2*design.stiHeight;
  design.auWidth-=Math.floor((area.auPadding+area.auPadding)*design.auWidth);//design.p*design.stiWidth;
  design.auHeight-=Math.floor((area.auPadding+area.auPadding)*design.auHeight);


  design.label='bad';


 
  if (design.tiCase==='upper') design.ti = title.title.trim().toUpperCase();
  if (design.tiCase==='lower') design.ti = title.title.trim().toLowerCase();

  if (design.stiCase==='upper') design.sti = title.subtitle.trim().toUpperCase();
  if (design.stiCase==='lower') design.sti = title.subtitle.trim().toLowerCase();

  if (design.auCase==='upper') design.au = title.au.trim().toUpperCase();
  if (design.auCase==='lower') design.au = title.au.trim().toLowerCase();

  [design.tiMinWordLength, design.tiMaxWordLength] = getMinMaxLen(design.ti);
  [design.stiMinWordLength, design.stiMaxWordLength] = getMinMaxLen(design.sti);
  [design.auMinWordLength, design.auMaxWordLength] = getMinMaxLen(design.au);

  design.tiNumWords=design.ti.split(' ').length;
  design.stiNumWords=design.sti.split(' ').length;
  design.auNumWords=design.au.split(' ').length;

  design.tiLength=design.ti.length;
  design.stiLength=design.sti.length;
  design.auLength=design.au.length;

  if (design.stiLength ==0) {
  design.stiX=0;
  design.stiY=0;
  design.stiWidth=0;
  design.stiHeight=0;
  design.stiAlign='NA';
  design.stiDirection='NA';
  design.stiFontSize=0;
  }


  if (design.auLength == 0 ) {
  design.auX=0;
  design.auY=0;
  design.auWidth=0;
  design.auHeight=0;
  design.auAlign='NA';
  design.auDirection='NA';
  design.auFontSize=0;
  }
  [design.au, design.numAuthors] = getNumAuthors(design.au);
  //console.log(design.au);

  return design;

}

